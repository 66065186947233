import React, { FC } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ServiceComponent from '../components/services';

import services, { discount } from '../data/services';

const Services: FC = () => {
  return (
    <Layout>
      <SEO title="Services" />
      {discount && (
        <div className="container box">
          <div className="has-text-centered">
            <h1 className="title is-2">
              New customers get <b className="has-text-danger">{discount}</b>{' '}
              off on their first treatment
            </h1>
          </div>
        </div>
      )}
      {services.map((service, idx) => (
        <ServiceComponent
          key={idx}
          number={idx}
          title={service.title}
          description={service.description}
          services={service.services}
          image={service.image}
        />
      ))}
    </Layout>
  );
};

export default Services;
