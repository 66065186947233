import React, { FC } from 'react';
import LeftImage from './leftImage';
import Right from './rightImage';
import { Services } from '../../models/services';
import '../../styles/services.scss';

const ServiceComponent: FC<Services> = ({
  number,
  title,
  description,
  services,
  image,
}: Services) => {
  return (
    <div className="container">
      <div className="section ">
        {typeof window !== 'undefined' && window.innerWidth <= 800 ? (
          <LeftImage
            number={number}
            title={title}
            description={description}
            services={services}
            image={image}
          />
        ) : (
          <>
            {number % 2 === 0 ? (
              <LeftImage
                number={number}
                title={title}
                description={description}
                services={services}
                image={image}
              />
            ) : (
              <Right
                number={number}
                title={title}
                description={description}
                services={services}
                image={image}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ServiceComponent;
