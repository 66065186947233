import React, { FC } from 'react';
import { Services } from '../../models/services';

const RightImage: FC<Services> = ({
  title,
  description,
  services,
  image,
}: Services) => {
  return (
    <div>
      <div>
        <div
          className="card columns is-vcentered"
          style={{
            margin: 'auto',
            width: '100%',
            padding: '10px',
          }}
        >
          <div className="card-content column">
            <div
              className="box"
              style={{
                margin: 'auto',
                height: '100%',
                padding: '10px',
              }}
            >
              <div className="is-desktop has-text-centered">
                <h1 className="title is-2">{title}</h1>
                <p className="message">{description}</p>
                {services.map((service, idx) => (
                  <p className="is-size-5 py-1" key={idx}>
                    {service}
                  </p>
                ))}
              </div>
            </div>
          </div>
          <div className="card-image column">
            <div
              className="image is-5by3"
              style={{
                backgroundImage: `url("${image}")`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightImage;
