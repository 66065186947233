export default [
  {
    title: 'Facials',
    description:
      'Taking care of your skin is more important than covering it up.',
    services: [
      'Hydrofacial - $140.00',
      'Classic Facial - $110.00',
      'Oxygen Therapy - $130.00',
      'Anti Aging Facial - $170.00',
      'Microneedling - $140.00',
      'Microdermabrasion - $120.00',
      'Event Facial - $70.00',
      "Gentlemen's Facial - $120.00",
      'Teen Facial - $80.00',
      'Back Facial - $110.00',
      'Buttocks Facial - $120.00',
      'Gua Sha Face Massage - $95.00',
      'Face Massage - $95.00',
      'Chemical Peel - $120.00',
      'BioRePeel - $200',
    ],
    image: '../../services/facial.jpg',
  },
  {
    title: 'Laser Hair Removal',
    description: 'Hair free is care free',
    services: [
      'Small Area of Face - $30.00',
      'Full Face - $100.00',
      'Bikini Line - $60.00',
      'Brazillian Bikini - $90.00',
      'Underarms - $50.00',
      'Brazillian Bikini + Underarms - $125.00',
      'Half Legs - $80.00',
      'Full Legs - $130.00',
      'Half Arms - $75.00',
      'Full Arms - $120.00',
      'Buttocks - $90.00',
      'Chest - $100.00',
      'Back - $100.00',
      'Full Body - custom',
    ],
    image: '../../services/laser.jpg',
  },
  {
    title: 'Permanent Makeup',
    description:
      "She wiped her tears and her eyebrows too... don't be like her.",
    services: [
      'Eyes - $250.00',
      'Eyebrows - $350.00',
      'Lips - $350.00',
      'Eyes Touch Up - $120.00',
      'Eyebrows Touch Up - $120.00',
      'Lips Touch Up - $150.00',
    ],
    image: '../../services/permanent.jpg',
  },
  {
    title: 'Ultrasound Cavitation Body Contouring Treatment',
    description: "It's all fun and games until your pants don't fit anymore",
    services: [
      'Back + Arms - $150.00',
      'Arms - $60.00',
      'Face - $85.00',
      'Butt + Upper Legs - $160.00',
      'Abdomen - $130.00',
    ],
    image: '../../services/body.jpg',
  },
  {
    title: 'Massages',
    description:
      'Treat a wide range of emotional and physical health problems.',
    services: [
      'Deep Tissue Massage - $115.00',
      'Relaxing Massage - $100.00',
      'Classic Massage - $100.00',
      'Face Massage - $95.00',
      'Honey Massage - $120.00',
      'Cupping Massage - $95.00',
      'Gua Sha Face Massage - $95.00',
      'Hot Stone Massage - $120.00',
      'Aromatherapeutic Massage - $130.00',
      'Lymphatic Drainage Massage - $110.00',
      'Face Lift Massage + Energy Charging - $120.00',
      'Combo: Hot Stone, Deep Tissue + Relaxing Massage - $180.00',
      'Face Lifting Massage + Ultrasound Cavitation - $140.00',
      'Foot Reflexology Massage - $60.00',
      'Thai Massage - $150.00',
    ],
    image: '../../services/massage.jpg',
  },
  {
    title: 'Sugaring',
    description: 'Natural hair removal that is actually good for your skin.',
    services: [
      'Half Legs - $30.00',
      'Full Legs - $60.00',
      'Half Arms - $25.00',
      'Full Arms - $40.00',
      'Upper Lip - $10.00',
      'Sideburns - $15.00',
      'Chin - $10.00',
      'Cheeks - $15.00',
      'Full Face - $35.00',
      'Bikini Sides - $30.00',
      'Brazillian Bikini - $50.00',
      'Bikini Plus - $40.00',
      'Butt Strip - $10.00',
      'Full Back - $30.00',
      'Lower Back - $15.00',
      'Full Belly - $20.00',
      'Lower Belly - $10.00',
      'Underarms - $20.00',
    ],
    image: '../../services/sugaring.jpg',
  },
];

export const discount = '15%';
